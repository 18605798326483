import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";

import { GET_LISTING_TYPE } from "./actionTypes"
import { getListingTypeFail, getListingTypeSuccess } from "./actions"

import { getListingTypeApi } from "../../../helpers/backend_helper"



function* fetchListingType({ agentId }) {
  try {
    const response = yield call(getListingTypeApi, agentId)
    yield put(getListingTypeSuccess(response))
  } catch (error) {
    yield put(getListingTypeFail(error))
  }
}



function* appObjectSaga() {




  yield takeEvery(GET_LISTING_TYPE, fetchListingType)



  
}


export default appObjectSaga
