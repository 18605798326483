import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom";
import {
  Breadcrumb, BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap"
import noImg from "../../assets/img/image-coming-soon.jpg"

import { getProjectInterest } from "../../store/projects/actions"
class myProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProjects: [],
    };
  }

  componentDidMount() {
    const { onGetProjectInterest } = this.props;
    onGetProjectInterest(localStorage.getItem('userId'));
}
handleCheckboxChange = (projectId) => {
  this.setState((prevState) => {
    const { selectedProjects } = prevState;
    return {
      selectedProjects: selectedProjects.includes(projectId)
        ? selectedProjects.filter((id) => id !== projectId) 
        : [...selectedProjects, projectId], 
    };
  });
};
handleSubmit = async () => {
  const { selectedProjects } = this.state;
  const clientId = parseInt(localStorage.getItem('userId'));
  const agentId = parseInt(localStorage.getItem('agentId')); 

  const payload = {
    projectIds: selectedProjects,
    agentId,
    clientId,
  };
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj.resultData.bearerToken;
  const str = 'Bearer ' + token;
  try {
    const response = await fetch("https://rscoreapi.azurewebsites.net/api/AgentClient/compareagentbrokerassignedprojectsfull", {
      method: "POST",
      headers: {
        Authorization: str, 
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Projects submitted successfully.");
      this.props.history.push({
        pathname: "/project-compare",
        state: { projects: data },
      });
    } else {
      console.error("Failed to submit projects.");
    }
  } catch (error) {
    console.error("Error submitting projects:", error);
  }
};
  render() {
    const { interestDetails } = this.props;
    const { selectedProjects } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Row>
          <Col xs="12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <div className=" d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18"> My Interest </h4>
                
                <Button
                  className="btn btn-success mt-3 custom-button"
                  onClick={this.handleSubmit}
                 
                  disabled={selectedProjects.length === 0} 
              >
              Compare properties

              <span className="badge bg-danger rounded-pill">{selectedProjects.length}</span>
              </Button>
                
               
              </div>
              <div className="page-title-right">
                <Breadcrumb listClassName="m-0">
                  <BreadcrumbItem>
                    <Link to="#">Project</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">My Interest</Link>
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </Col>
        </Row>


           

            

            <Row>
            {map(interestDetails, (project, projectkey) => (
              <Col sm="3" key={projectkey}>
                <div className="product">
                    <div className="pro-img">
                        <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                       
                        <img src={project?.projectInfo?.projectLogoLink ? project?.projectInfo?.projectLogoLink : noImg } className="img-fluid" style={{height:70}} />
                    </div>
                    <div className="prd-info">
                        <h5 className="location">{project?.projectInfo?.city}</h5>
                        <h4 className="prd-title"><Link to={`/project-detail/` + project?.projectInfo?.id + "/" + project?.projectInfo?.projectId }>{project?.projectInfo?.projectName}</Link>  <span className="float-end">
                        <input
                            type="checkbox"
                            className="project-checkbox"
                            checked={selectedProjects.includes(
                              project?.projectInfo?.projectId
                            )}
                            onChange={() =>
                              this.handleCheckboxChange(project?.projectInfo?.projectId)
                            }
                          />
                          
                         </span></h4>
                    </div>
                </div>

              </Col>
            ))}
            </Row>


           
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
myProject.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  onGetProjectInterest:PropTypes.func,
  interestDetails:PropTypes.array,
  loading:PropTypes.object
}

const mapStateToProps = ({ projects }) => (
    {
        interestDetails:projects.interestDetails,
        loading:projects.loading
  
    })

    const mapDispatchToProps = dispatch => ({
        
      onGetProjectInterest:(clientId) => dispatch(getProjectInterest(clientId))
       
      });
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(myProject)));

