import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {  map, capitalize } from "lodash";
//import Lead from "./Lead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { addClientAddListing, getClientAddListing } from "../../store/addlisting/actions"
import CurrencyFormat from 'react-currency-format';
import Switch from "react-switch"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Tooltip
} from "reactstrap"

class addListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.toggle = this.toggle.bind(this);
    this.handleAddListingSubmit = this.handleAddListingSubmit.bind(this)
  }
  toggle () {
    this.setState({
      open: !this.state.open
    })
  }
  handleAddListingSubmit(value){
    
    // this.props.addClientAddListing(value, this.props.history);

  }
  componentDidMount() {
    const {  onGetClientAddListing } = this.props;
   
    onGetClientAddListing(localStorage.getItem('userId'),localStorage.getItem('agentId'))
   
}
handleChange = (activeStatus, itemId) => {
    
};
  render() {
    const { addListings, loading } = this.props;
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Checklist  | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Seller")}
              breadcrumbItem={this.props.t("Checklist")}
              heading={this.props.t("Checklist of things to be done")}
            />
            {/* <h1>Checklist of things to be done</h1> */}

            
                  <Row>
                    <Col sm="12">
                      <Card className="rs-card-bg" style={{fontFamily:'Helvetica '}}> 
                        <CardBody>
                          <CardTitle>Welcome to your checklist page - your ultimate tool for staying organized throughout your sale purchase</CardTitle>
                          <p style={{fontFamily:'Helvetica '}}>
                          Here, you can keep track of all the essential tasks and requirements, ensuring a smooth and stress-free process. By regularly updating and ticking off completed tasks, you&apos;ll have a clear overview of what&apos;s been accomplished and what still needs attention, leaving no room for surprises later on. 
                          Let&apos;s make sure every detail is covered so you can confidently navigate your home sale journey!
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                </Row>
            <Row>
              
              
            <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    check1: (this.state && this.state.check1) || false,
                   
                    agentId: localStorage.getItem('agentId'),
                    clientId: localStorage.getItem('userId')

                  }}
                  validationSchema={Yup.object().shape({
                    // check1: Yup.string().required("This is Required"),
                    // mlsurl: Yup.string().required("This is Required"),
                    // listedPrice:Yup.number().typeError('Price should be a number').required("This is Required")
                  })}

                  onSubmit={this.handleAddListingSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card className="rs-card-bg"> 
                    <CardBody>
                    <h5 className="card-title"></h5>

                    <Row>
                    {/* <h4>Tips for after you move in</h4> */}
                    <Col  >
                    <Row>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="3check1"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check1"
                          />
                          <Label htmlFor="3check1" className="form-check-label">
                            {this.props.t("Clean the House")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="3check2"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check2"
                          />
                          <Label htmlFor="3check2" className="form-check-label">
                            {this.props.t("Close All Valves and Turn Off All Switches")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="3check3"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check3"
                          />
                          <Label htmlFor="3check3" className="form-check-label">
                            {this.props.t("Don't Skip the Final Walk-Through")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="3check4"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check4"
                          />
                          <Label htmlFor="3check4" className="form-check-label">
                            {this.props.t("Check cabinets, drawers, and storage areas for any forgotten items")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="3check5"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check5"
                          />
                          <Label htmlFor="3check5" className="form-check-label">
                            {this.props.t("Leave all house keys, remotes, gate keys, pool keys, and mailbox keys for the new owner.")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="3check6"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check6"
                          />
                          <Label htmlFor="3check6" className="form-check-label">
                            {this.props.t("Assemble a packet of appliance manuals, receipts, and any warranties as well.")}
                          </Label>
                      </FormGroup>
                      </Col>
                      </Row>

                    </Col>
                  </Row>

                  <hr className="mb-2 mt-2"/>
              
                  <Row>
                  <h5 className="pb-3 text-uppercase">General Things</h5>
                    <Col  >
                    <Row>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check1"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check1"
                          />
                          <Label htmlFor="check1" className="form-check-label">
                            {this.props.t("Buy boxes and other moving supplies")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check2"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check2"
                          />
                          <Label htmlFor="check2" className="form-check-label">
                            {this.props.t("Double-check that your boxes are labeled correctly")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check3"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check3"
                          />
                          <Label htmlFor="check3" className="form-check-label">
                            {this.props.t("Hire a moving company")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check4"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check4"
                          />
                          <Label htmlFor="check4" className="form-check-label">
                            {this.props.t("Take pictures or videos of every complex electronic connection")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check5"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check5"
                          />
                          <Label htmlFor="check5" className="form-check-label">
                            {this.props.t("Empty your fridge")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check6"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check6"
                          />
                          <Label htmlFor="check6" className="form-check-label">
                            {this.props.t("Pack a bag with all essentials for moving day")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check7"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check7"
                          />
                          <Label htmlFor="check7" className="form-check-label">
                            {this.props.t(" Have some cash-on-hand")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check8"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check8"
                          />
                          <Label htmlFor="check8" className="form-check-label">
                            {this.props.t("Clean your home")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check9"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check9"
                          />
                          <Label htmlFor="check9" className="form-check-label">
                            {this.props.t("Pack a first moving day box with first-day essentials")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check10"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check10"
                          />
                          <Label htmlFor="check10" className="form-check-label">
                            {this.props.t("Do a final walk through before exiting the home")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="check11"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check11"
                          />
                          <Label htmlFor="check11" className="form-check-label">
                            {this.props.t("Take pictures of the empty home")}
                          </Label>
                      </FormGroup>
                      </Col>
                      </Row>
                    </Col>
                  </Row>

                  <hr className="mt-2 mb-3"/>

                  <Row>
                  <h5 className="pb-3 text-uppercase">Change of address</h5>
                    <Col  >
                    <Row>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="1check1"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check1"
                          />
                          <Label htmlFor="1check1" className="form-check-label">
                            {this.props.t("Inform post office to forward mail")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="1check2"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check2"
                          />
                          <Label htmlFor="1check2" className="form-check-label">
                            {this.props.t("Drivers license")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="1check3"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check3"
                          />
                          <Label htmlFor="1check3" className="form-check-label">
                            {this.props.t("health Card")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="1check4"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check4"
                          />
                          <Label htmlFor="1check4" className="form-check-label">
                            {this.props.t("Insurance companies")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="1check5"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check5"
                          />
                          <Label htmlFor="1check5" className="form-check-label">
                            {this.props.t("Bank accounts & credit cards")}
                          </Label>
                      </FormGroup>
                      </Col>
                      <Col sm='6'>
                      <FormGroup className="mb-3">
                          <Field
                            name="1check6"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check6"
                          />
                          <Label htmlFor="1check6" className="form-check-label">
                            {this.props.t("Subscriptions Internet, TV and telecom service providers")}
                          </Label>
                      </FormGroup>
                      </Col>
                      </Row>
                      {/* <FormGroup className="mb-3">
                      <Label htmlFor="1check7" className="form-label">
                            {this.props.t(" Internet, TV and telecom service providers")}
                          </Label>
                          <Field
                            name="1check7"
                            onChange={handleChange}   
                            className='form-control me-2'  
                            
                            type="text"
                            id="1check7"
                          />
                      </FormGroup> */}

          

                    </Col>
                  </Row>

                  <Row>
                    <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 27}} type="submit" className="btn btn-primary w-md">Save</button></div></Col>
                  </Row>
                  
             
             </CardBody>
                </Card>
                
                </Form>
                  )}
                </Formik>
			 
			
			
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
addListing.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientAddListing:PropTypes.func,
  addListings:PropTypes.array,
  onGetClientAddListing:PropTypes.func,
  loading:PropTypes.object

}
const mapStateToProps = ({ addListing }) => (
  {
    addListings:addListing.addListings,
    loading:addListing.loading

  })
const mapDispatchToProps = dispatch => ({
  
  addClientAddListing: (data) => dispatch(addClientAddListing(data)),
  onGetClientAddListing:(clientId, agentId) => dispatch(getClientAddListing(clientId, agentId))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(addListing))
