import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Login Method
const postLogin = data => post(url.POST_CLIENT_LOGIN, data)
export const getLocalDataApi = () => get(url.GET_LOCAL_DATA_URL)
const postClientMessageAPI = data => post(url.POST_CLIENT_MESSAGE_API_URL, data)
export const getClientMessageAPI = ClientId => get(`${url.GET_CLIENT_MESSAGE_API_URL}/`, { params: { ClientId } })


const postClientDocumentAPI = data => post(url.POST_CLIENT_DOCUMENT_API_URL, data)
export const getClientDocumentAPI = ClientId => get(`${url.GET_CLIENT_DOCUMENT_API_URL}/`, { params: { ClientId } })
export const getClientPrefQuestAPI = forType => get(`${url.GET_CLIENT_PREF_QUEST_API_URL}/`, { params: { forType } })
export const getAllProjectAPI = agentId => get(`${url.GET_ALL_PROJECT_API_URL}/`, { params: { agentId } })
export const getAllProjectDetailAPI = (projectId, agentId, clientId ) => get(`${url.GET_PROJECT_DETAIL_API_URL}/`, { params: { projectId, agentId, clientId } })
const postProjetInterestAPI = data => post(url.POST_PROJECT_INTEREST_API_URL, data)
export const getProjetInterestAPI = ClientId => get(`${url.GET_PROJECT_INTEREST_API_URL}/`, { params: { ClientId } })

export const postClientSellerShowing = data => post(url.POST_CLIENT_SELLER_SHOWING_API_URL, data)
export const getClientSellerShowingUrl = clientId => get(`${url.GET_CLIENT_SELLER_SHOWING_API}/`, { params: { clientId } })
export const deleteClientShowingAPI   = id =>   del(`${url.DELETE_CLIENT_SHOWING_API}/${id}`)
export const updateClientSellerShowingAPI = data => put(url.UPDATE_CLIENT_SELLER_SHOWING_API_URL, data)

export const getClientSellerListingUrl = clientId => get(`${url.GET_CLIENT_SELLER_LISTING_API}/`, { params: { clientId } })
const postClientAppointment = data => post(url.POST_CLIENT_APPOINTMENT_API, data)
export const getClientAppointment = (agentId, clientId) => get(`${url.GET_CLIENT_APPOINTMENT_API}/`, { params: { agentId, clientId } })
export const deleteClientAppointmentAPI   = id =>   del(`${url.DELETE_CLIENT_APPOINTMENT_API}/${id}`)
const postBuyerDestinationUrl = data => post(url.POST_BUYER_DESTINATION_API, data)

export const getProjectFeatApi = projectId => get(`${url.GET_PROJECT_FEATURE_API_URL}/`, { params: { projectId } })
export const getProjectLinkApi            = projectId => get(`${url.GET_PROJECT_LINK_API_URL}/`, { params: { projectId } })
export const getProjectGalleryApi            = projectId => get(`${url.GET_PROJECT_GALLERY_API_URL}/`, { params: { projectId } })


export const postClientAddListing = data => post(url.POST_CLIENT_ADD_LISTING_API, data)
export const getClientAddListingUrl  = (clientId, agentId ) => get(`${url.GET_CLIENT_ADD_LISTING_API}/`, { params: { clientId, agentId } })
export const deleteClientDocumentAPI   = id =>   del(`${url.DELETE_CLIENT_DOCUMENT_API}/${id}`)
export const updateClientDocumnetAPI   = document => put(url.UPDATE_CLIENT_DOCUMENT_API, document)

export const updateClientAppointmentAPI = data => put(url.UPDATE_CLIENT_APPOINTMENT_API, data)

export const getClientDetailUrl= clientId => get(`${url.GET_CLIENT_DETAIL_API}${clientId}`)
export const updateClientProfileAPI   = data => put(url.UPDATE_CLIENT_PROFILE_API, data)
export const updateClientPrimaryConatctAPI   = data => put(url.UPDATE_CLIENT_PRIMARY_CONTACT_API, data)
export const updateClientSecondaryConatctAPI   = data => put(url.UPDATE_CLIENT_SECONDARY_CONTACT_API, data)
export const postClientImportantDateAPI = data => post(url.CREATE_CLIENT_IMPORTANT_DATE_URL, data)
export const getClientImportantDateAPI = clientId => get(`${url.GET_CLIENT_IMPORTANT_DATE_URL}/`, { params: { clientId } })
export const deleteClientImportantDateAPI   = id =>   del(`${url.DELETE_CLIENT_IMPORTANT_DATE_URL}/${id}`)
export const postClientChildAPI = data => post(url.CREATE_CLIENT_CHILD_URL, data)
export const getClientChildAPI = clientId => get(`${url.GET_CLIENT_CHILD_URL}/`, { params: { clientId } })

export const getClientUpcompingActivitiesAPI =  (clientId, agentId) => get(`${url.GET_CLIENT_UPCOMING_ACTIVITIES_API}/`, { params: { clientId, agentId } })
export const getClientRecentActivitiesAPI = (clientId, agentId) => get(`${url.GET_CLIENT_RECENT_ACTIVITIES_API}/`, { params: { clientId, agentId } })

export const postHomePrefUrl = data => post(url.POST_HOME_DESTINATION_API, data)
export const getHomePrefUrl= clientId => get(`${url.GET_HOME_DESTINATION_API}/`, { params: { clientId } })
export const updateHomePrefUrl = data => put(url.UPDATE_HOME_DESTINATION_API, data)
export const deleteHomePrefUrl = id => del(`${url.DELETE_HOME_DESTINATION_API}/${id}`)



export const postImpDestUrl = data => post(url.POST_IMP_DESTINATION_API, data)
export const getImpDestUrl= clientId => get(`${url.GET_IMP_DESTINATION_API}`, { params: { clientId } })


export const postAppLocationTypeAPI = data => post(url.CREATE_APPOINTMENT_LOCATION_TYPE_URL, data)
export const getAppLocationTypeAPI = agentId => get(`${url.GET_APPOINTMENT_LOCATION_TYPE_URL}/`, { params: { agentId } })
export const updateAppLocationTypeAPI = data => put(url.UPDATE_APPOINTMENT_LOCATION_TYPE_URL, data)
export const deleteAppLocationTypeAPI = id => del(`${url.DELETE_APPOINTMENT_LOCATION_TYPE_URL}/${id}`)


export const postAppointmentPriorityAPI = data => post(url.CREATE_APPOINTMENT_PRIORITY_URL, data)
export const getAppointmentPriorityAPI = agentId => get(`${url.GET_APPOINTMENT_PRIORITY_URL}/`, { params: { agentId } })
export const updateAppointmentPriorityAPI = data => put(url.UPDATE_APPOINTMENT_PRIORITY_URL, data)
export const deleteAppointmentPriorityAPI = id => del(`${url.DELETE_APPOINTMENT_PRIORITY_URL}/${id}`)


export const postAppointmentTypeAPI = data => post(url.CREATE_APPOINTMENT_TYPE_URL, data)
export const getAppointmentTypeAPI = agentId => get(`${url.GET_APPOINTMENT_TYPE_URL}/`, { params: { agentId } })
export const updateAppointmentTypeAPI = data => put(url.UPDATE_APPOINTMENT_TYPE_URL, data)
export const deleteAppointmentTypeAPI = id => del(`${url.DELETE_APPOINTMENT_TYPE_URL}/${id}`)


export const postContactLevelAPI = data => post(url.CREATE_CONTACT_LEVEL_URL, data)
export const getContactLevelAPI = agentId => get(`${url.GET_CONTACT_LEVEL_URL}/`, { params: { agentId } })
export const updateContactLevelAPI = data => put(url.UPDATE_CONTACT_LEVEL_URL, data)
export const deleteContactLevelAPI = id => del(`${url.DELETE_CONTACT_LEVEL_URL}/${id}`)


export const postContactStatusAPI = data => post(url.CREATE_CONTACT_STATUS_URL, data)
export const getContactStatusAPI = agentId => get(`${url.GET_CONTACT_STATUS_URL}/`, { params: { agentId } })
export const updateContactStatusAPI = data => put(url.UPDATE_CONTACT_STATUS_URL, data)
export const deleteContactStatusAPI = id => del(`${url.DELETE_CONTACT_STATUS_URL}/${id}`)


export const postContactTypeAPI = data => post(url.CREATE_CONTACT_TYPE_URL, data)
export const getContactTypeAPI = agentId => get(`${url.GET_CONTACT_TYPE_URL}/`, { params: { agentId } })
export const updateContactTypeAPI = data => put(url.UPDATE_CONTACT_TYPE_URL, data)
export const deleteContactTypeAPI = id => del(`${url.DELETE_CONTACT_TYPE_URL}/${id}`)


export const postLeadClassificationAPI = data => post(url.CREATE_LEAD_CLASSIFICATION_URL, data)
export const getLeadClassificatioAPI = agentId => get(`${url.GET_LEAD_CLASSIFICATION_URL}/`, { params: { agentId } })
export const updateLeadClassificatioAPI = data => put(url.UPDATE_LEAD_CLASSIFICATION_URL, data)
export const deleteLeadClassificatioAPI = id => del(`${url.DELETE_LEAD_CLASSIFICATION_URL}/${id}`)

export const postLeadSourceAPI = data => post(url.CREATE_LEAD_SOURCE_URL, data)
export const getLeadSourceAPI = agentId => get(`${url.GET_LEAD_SOURCE_URL}/`, { params: { agentId } })
export const updateLeadSourceAPI = data => put(url.UPDATE_LEAD_SOURCE_URL, data)
export const deleteLeadSourceAPI = id => del(`${url.DELETE_LEAD_SOURCE_URL}/${id}`)

export const postLeadStatusAPI = data => post(url.CREATE_LEAD_STATUS_URL, data)
export const getLeadStatusAPI = agentId => get(`${url.GET_LEAD_STATUS_URL}/`, { params: { agentId } })
export const updateLeadStatusAPI = data => put(url.UPDATE_LEAD_STATUS_URL, data)
export const deleteLeadStatusAPI = id => del(`${url.DELETE_LEAD_STATUS_URL}/${id}`)

export const postLeadTypeAPI = data => post(url.CREATE_LEAD_TYPE_URL, data)
export const getLeadTypeAPI = agentId => get(`${url.GET_LEAD_TYPE_URL}/`, { params: { agentId } })
export const updateLeadTypeAPI = data => put(url.UPDATE_LEAD_TYPE_URL, data)
export const deleteLeadTypeAPI = id => del(`${url.DELETE_LEAD_TYPE_URL}/${id}`)


export const postNoteTypeAPI = data => post(url.CREATE_NOTE_TYPE_URL, data)
export const getNoteTypeAPI = agentId => get(`${url.GET_NOTE_TYPE_URL}/`, { params: { agentId } })
export const updateNoteTypeAPI = data => put(url.UPDATE_NOTE_TYPE_URL, data)
export const deleteNoteTypeAPI = id => del(`${url.DELETE_NOTE_TYPE_URL}/${id}`)


export const postProjectDocTypeAPI = data => post(url.CREATE_PROJECT_DOC_TYPE_URL, data)
export const getProjectDocTypeAPI = agentId => get(`${url.GET_PROJECT_DOC_TYPE_URL}/`, { params: { agentId } })
export const updateProjectDocTypeAPI = data => put(url.UPDATE_PROJECT_DOC_TYPE_URL, data)
export const deleteProjectDocTypeAPI = id => del(`${url.DELETE_PROJECT_DOC_TYPE_URL}/${id}`)


export const postProjectStatusAPI = data => post(url.CREATE_PROJECT_STATUS_URL, data)
export const getProjectStatusAPI = agentId => get(`${url.GET_PROJECT_STATUS_URL}/`, { params: { agentId } })
export const updateProjectStatusAPI = data => put(url.UPDATE_PROJECT_STATUS_URL, data)
export const deleteProjectStatusAPI = id => del(`${url.DELETE_PROJECT_STATUS_URL}/${id}`)

export const postProjectTypeAPI = data => post(url.CREATE_PROJECT_TYPE_URL, data)
export const getProjectTypeAPI = agentId => get(`${url.GET_PROJECT_TYPE_URL}/`, { params: { agentId } })
export const updateProjectTypeAPI = data => put(url.UPDATE_PROJECT_TYPE_URL, data)
export const deleteProjectTypeAPI = id => del(`${url.DELETE_PROJECT_TYPE_URL}/${id}`)

export const postServiceTypeAPI = data => post(url.CREATE_SERVICE_TYPE_URL, data)
export const getServiceTypeAPI = agentId => get(`${url.GET_SERVICE_TYPE_URL}/`, { params: { agentId } })
export const updateServiceTypeAPI = data => put(url.UPDATE_SERVICE_TYPE_URL, data)
export const deleteServiceTypeAPI = id => del(`${url.DELETE_SERVICE_TYPE_URL}/${id}`)

export const postTaskPriorityAPI = data => post(url.CREATE_TASK_PRIORITY_URL, data)
export const getTaskPriorityAPI = agentId => get(`${url.GET_TASK_PRIORITY_URL}/`, { params: { agentId } })
export const updateTaskPriorityAPI = data => put(url.UPDATE_TASK_PRIORITY_URL, data)
export const deleteTaskPriorityAPI = id => del(`${url.DELETE_TASK_PRIORITY_URL}/${id}`)

export const postTaskReminderAPI = data => post(url.CREATE_TASK_REMINDER_URL, data)
export const getTaskReminderAPI = agentId => get(`${url.GET_TASK_REMINDER_URL}/`, { params: { agentId } })
export const updateTaskReminderAPI = data => put(url.UPDATE_TASK_REMINDER_URL, data)
export const deleteTaskReminderAPI = id => del(`${url.DELETE_TASK_REMINDER_URL}/${id}`)


export const postTaskStatusAPI = data => post(url.CREATE_TASK_STATUS_URL, data)
export const getTaskStatusAPI = agentId => get(`${url.GET_TASK_STATUS_URL}/`, { params: { agentId } })
export const updateTaskStatusAPI = data => put(url.UPDATE_TASK_STATUS_URL, data)
export const deleteTaskStatusAPI = id => del(`${url.DELETE_TASK_STATUS_URL}/${id}`)

export const postTaskTypeAPI = data => post(url.CREATE_TASK_TYPE_URL, data)
export const getTaskTypeAPI = agentId => get(`${url.GET_TASK_TYPE_URL}/`, { params: { agentId } })
export const updateTaskTypeAPI = data => put(url.UPDATE_TASK_TYPE_URL, data)
export const deleteTaskTypeAPI = id => del(`${url.DELETE_TASK_TYPE_URL}/${id}`)
export const getListingTypeApi = agentId => get(`${url.GET_LISTING_TYPE_API_URL}/`, { params: { agentId } })
export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postClientMessageAPI,
  postClientDocumentAPI,
  postProjetInterestAPI,
  postClientAppointment,
  postBuyerDestinationUrl

}