import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import lawyerIcon from '../../assets/images/lawyer.png'
import agentIcon from '../../assets/images/agent.png'

//import Lead from "./Lead";

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "./calander"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,

  Modal,
  CardImg,
  CardFooter,
  CardHeader
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
class MyLawyer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
    this.toggleRightCanvasLawyer = this.toggleRightCanvasLawyer.bind(this);
    this.toggleRightCanvasAgent = this.toggleRightCanvasAgent.bind(this);
    
  }

  toggleRightCanvasLawyer() {
    this.setState({ isRightLawyer: !this.state.isRightLawyer });
  }

  
  toggleRightCanvasAgent() {
    this.setState({ isRightAgent: !this.state.isRightAgent });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Legal Contacts  | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Settings")}
              breadcrumbItem={this.props.t("Legal Contacts")}
              heading={this.props.t("Set your professional team")}
            />
            {/* <h1>Set your professional team</h1> */}
            <Row>
              <Col xl="12">
              <Row>
                    <Col sm="12">
                      <Card className="mini-stats-wid" style={{backgroundColor:'#eff7ff',fontFamily:'Helvetica '}}> 
                        <CardBody>
                          <CardTitle>Welcome to our Share Your Contacts page!</CardTitle>
                          <p style={{fontFamily:'Helvetica '}}>
                          Here you can securely provide your lawyer and Mortgage broker details with me. This allows me quick access to your trusted professionals.<br/><br/>
                          let&apos;s simplify the process and ensure everyone is on the same page for a smooth home buying or selling experience
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                </Row>

                <Card className="mini-stats-wid"> 
                    <CardBody>
                      <Row className="mb-5">
                        <Col sm='2'></Col>
                        <Col sm='4'>
                        <Link onClick={this.toggleRightCanvasLawyer} to="#">
                    <Card className="me-2">

                      <CardImg src={lawyerIcon} className="px-5 py-3 mb-4" style={{border: '4px solid #dadee3'}}/>
                      <CardFooter className="mt-0 text-center" style={{background:'#28b5bd'}}><h3 style={{color:'white'}}>Set Your Real State Lawyer Info</h3></CardFooter>
                    </Card>
                    </Link>
                        </Col>
                        <Col sm='4'>
                        <Link onClick={this.toggleRightCanvasAgent} to="#">
                    <Card className="me-2">
                      <CardImg src={agentIcon} className="px-5 py-3 mb-4" style={{border: '4px solid #dadee3'}}/>
                      <CardFooter className="mt-0 text-center" style={{background:'#d56dce'}}><h3 style={{color:'white'}}>Set Your Mortgage Agent Info</h3></CardFooter>
                    </Card>
                    </Link>
                        </Col>
                        </Row>
                        <Row className="mb-5"></Row>
                    {/* <Row >
                      <Col sm='2'></Col>
    <Col sm='4'> */}
                    {/* <Card className="me-2">
                    <CardHeader className="text-center" style={{background:'#28b5bd'}} ><h3 style={{color:'white'}}>Enter Contact Info</h3></CardHeader>
                    <CardBody style={{border: '4px solid #dadee3'}}>
                                <p>Business Name </p>
                                <p>First Name </p>
                                <p>Last Name </p>
                                <p>Title </p>
                                <p>Address </p>
                                <p>Mobile </p>
                                <p className="">Office <span className="me-5 float-end">Ext</span></p>
                                <p>Fax </p>
                                <p>Email </p>
                                <p>Note (if any) </p>
                              </CardBody>
                    </Card> */}
                    {/*
                        </Col>
                        <Col sm='4'>
                          
                            <Card className="me-2">
                              <CardHeader className="text-center" style={{background:'#d56dce'}} ><h3 style={{color:'white'}}>Enter Contact Info</h3></CardHeader>
                              <CardBody style={{border: '4px solid #dadee3'}}>
                                <p>Business Name </p>
                                <p>First Name </p>
                                <p>Last Name </p>
                                <p>Title </p>
                                <p>Address </p>
                                <p>Mobile </p>
                                <p className="">Office <span className="me-5 float-end">Ext</span></p>
                                <p>Fax </p>
                                <p>Email </p>
                                <p>Note (if any) </p>
                              </CardBody>
                          </Card>
                       
                      </Col>
                      </Row> */}

                    </CardBody>
                </Card>
              {/* <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className="card-title">My Lawyer for Selling</h5>
              
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Name / Org")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Email")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Phone")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        
                    </Row>
                    <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Address")}
                            </Label>
                           <textarea 
                          
                           className="form-control"></textarea>

                          </FormGroup>

                        </Col>
                    </Row>
                    
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>
             
             </CardBody>
                </Card>


                <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className="card-title">My Lawyer for Buying</h5>
              
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Name / Org")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Email")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Phone")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        
                    </Row>
                    <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Address")}
                            </Label>
                           <textarea 
                          
                           className="form-control"></textarea>

                          </FormGroup>

                        </Col>
                    </Row>
                    
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>
             
             </CardBody>
                </Card> */}
{/* 
                <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className="card-title">My Mortgage Agent</h5>
              
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Name / Org")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Email")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Phone")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        
                    </Row>
                    <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Address")}
                            </Label>
                           <textarea 
                          
                           className="form-control"></textarea>

                          </FormGroup>

                        </Col>
                    </Row>
                    
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>
             
             </CardBody>
                </Card> */}
            </Col>
            
            </Row>
                    <Offcanvas
                      isOpen={this.state.isRightLawyer}
                      direction="end"
                      toggle={this.toggleRightCanvasLawyer}
                      style={{width:600}}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvasLawyer}>
                        My Lawyer
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Row>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Title")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("First Name")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Last Name")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        
                    </Row>
                    <Row>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Business Name")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Address")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Mobile")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Office")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Ext.")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Fax")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />
                          </FormGroup>

                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Email")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Notes (if any)")}
                            </Label>
                           <textarea 
                          
                           className="form-control"></textarea>

                          </FormGroup>

                        </Col>
                    </Row>
                    
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>
                      </OffcanvasBody>
                    </Offcanvas>

                    <Offcanvas
                      isOpen={this.state.isRightAgent}
                      direction="end"
                      toggle={this.toggleRightCanvasAgent}
                      style={{width:600}}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvasAgent}>
                       My Mortgage Agent
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Row>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Title")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("First Name")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        <Col sm="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Last Name")}
                            </Label>
                           <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>
                        </Col>

                        
                    </Row>
                    <Row>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Business Name")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Address")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Mobile")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Office")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Ext.")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Fax")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />
                          </FormGroup>

                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Email")}
                            </Label>
                            <input 
                           type="text"
                           className="form-control" />

                          </FormGroup>

                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Notes (if any)")}
                            </Label>
                           <textarea 
                          
                           className="form-control"></textarea>

                          </FormGroup>

                        </Col>
                    </Row>
                    
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>
                      </OffcanvasBody>
                    </Offcanvas>

           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
MyLawyer.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(MyLawyer))
