import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import noImg from "../../assets/img/image-coming-soon.jpg"
import {
  Container,
  Row,
  Col,
  Card, 
  CardBody,
  
} from "reactstrap"

class ProjectComapre extends Component {
  render() {
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const { state } = this.props.location || {};
    const projects = state?.projects || []; 
    console.log(projects);
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
              title={this.props.t("Project")}
              breadcrumbItem={this.props.t("Project Compare")}
            />
            <Row>
                <Col lg="12">
                <Card>
                <CardBody>
                {projects.length > 0 ? (
                <div className="keyDifferences">
                <table data-gsco-table="true" data-gsco-wrap="true" className="container" data-track-component="CompareBox">
  <thead className="gsc_co_open">
    <tr>
   
      <th className="headingOverview comparefixedO"></th>
      {projects.map((project, index) => (
          <th style={{textAlign:"center"}} key={index}>
            <img
              src={project.projectLogoLink || noImg}
              alt="Project"
              className="img-fluid"
              width="100px"
            />
            <br />
            {project.projectName || "N/A"}
          </th>
        ))}
     
    </tr>
  </thead>
  <tbody data-gsco-content="true" className="differencesPanel" data-track-section="overview">
  <tr className="  " data-common-row="false">
   
   <td className="comparefixedO leftPanel" data-responsivecell="true">City</td>
     {projects.map((project, index) => (
        <td key={index} className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">
           {project.projectSiteAddress?.city || "N/A"}
           </div></td>
       ))}

</tr>
    <tr className="  " data-common-row="false">
   
                          <td className="comparefixedO leftPanel" data-responsivecell="true">Builder</td>
                            {projects.map((project, index) => (
                               <td key={index} className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">
                                  {project.projectBuilderInfo?.builderName || "N/A"}
                                  </div></td>
                              ))}
    
    </tr>
    <tr className="  " data-common-row="false">

      <td className="comparefixedO leftPanel" data-responsivecell="true">Type</td>
                            {projects.map((project, index) => (
                               <td key={index} className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">
                                  { localization["ProjectType_"+ project.projectType] || "N/A"}
                                  </div></td>
                              ))}
    </tr>

    <tr className="  " data-common-row="false">
 
      <td className="comparefixedO leftPanel" data-responsivecell="true">Estimate Occupancy</td>
      {projects.map((project, index) => (
                               <td key={index} className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">
                                 {project.complitionMonth && project.complitionYear
                                    ? `${project.complitionMonth} ${project.complitionYear}`
                                    : "N/A"}
                                  </div></td>
                              ))}
    
    </tr>
    <tr className="  " data-common-row="false">

<td className="comparefixedO leftPanel" data-responsivecell="true">Price/SqFt</td>
{projects.map((project, index) => (
                               <td key={index} className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">
                                 {project.costPerSqFt
                                  ? `$${project.costPerSqFt}`
                                  : "N/A"}
                                  </div></td>
                              ))}
</tr>
<tr className="  " data-common-row="false">
  
      <td className="comparefixedO leftPanel" data-responsivecell="true">Units</td>
      {projects.map((project, index) => (
                               <td key={index} className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">
                                 {project.noOfUnits
                                  ? `${project.noOfUnits} Units`
                                  : "-"}
                                   {" "}|{" "} 
                                  {project.noOfStories
                                  ? `${project.noOfStories} Stories`
                                  : "-"}

                                  </div></td>
                              ))}
      
    </tr>
    <tr className="  " data-common-row="false">
 
      <td className="comparefixedO leftPanel" data-responsivecell="true">Price</td>
    
      {projects.map((project, index) => (
          <td key={index} className="compareThreeBlock rightPanel " data-responsivecelltwo="true">
      {project.minPriceRange
                                  ? `From $${project.minPriceRange} Units`
                                  : "N/A"}
                                   {" "}to{" "} 
                                  {project.maxPriceRange
                                  ? `$${project.maxPriceRange}`
                                  : "N/A"}                         
      </td>
    ))}
    </tr>
   

    
    
    <tr className="  " data-common-row="false">
   
      <td className="comparefixedO leftPanel" data-responsivecell="true">Area</td>
      {projects.map((project, index) => (
                               <td key={index} className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">
                                 {project.minArea
                                  ? `${project.minArea}`
                                  : "N/A"}
                                   {" "}|{" "} 
                                  {project.maxArea
                                  ? `${project.maxArea} SqFt`
                                  : "N/A"}

                                  </div></td>
                              ))}
    </tr>
    <tr className="  " data-common-row="false">
 
 <td className="comparefixedO leftPanel" data-responsivecell="true">Bedrooms</td>
 {projects.map((project, index) => (
                               <td key={index} className="compareThreeBlock rightPanel " data-responsivecelltwo="true"><div className="onlytext">
                                 {project.minBed
                                  ? `${project.minBed}`
                                  : "N/A"}
                                   {" "}|{" "} 
                                  {project.maxBed
                                  ? `${project.maxBed}`
                                  : "N/A"}

                                  </div></td>
                              ))}
</tr>
    
   
  </tbody>
</table>
                </div>
                ): (
                  <p>No projects selected for comparison.</p>
                )}

                    </CardBody>
                </Card>
                </Col>
                
            </Row>
            
           
            
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ProjectComapre.propTypes = {
    t: PropTypes.any,
    projects:PropTypes.array,
    location: PropTypes.object
  
  }

export default connect()(withTranslation()(ProjectComapre))
