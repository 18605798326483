import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {  map, capitalize } from "lodash";
//import Lead from "./Lead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { addClientAddListing, getClientAddListing } from "../../store/addlisting/actions"
import CurrencyFormat from 'react-currency-format';
import Switch from "react-switch"
import typeView from "../../assets/images/type-view.png"
import uploadView from "../../assets/images/icons/upload.png"
import gridView from "../../assets/images/grid-view.png"
import downloadView from "../../assets/images/icons/down.png"
import compareImg from "../../assets/images/icons/compare.png"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Tooltip
} from "reactstrap"

class addListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.toggle = this.toggle.bind(this);
    this.handleAddListingSubmit = this.handleAddListingSubmit.bind(this)
  }
  toggle () {
    this.setState({
      open: !this.state.open
    })
  }
  handleAddListingSubmit(value){
    
    this.props.addClientAddListing(value, this.props.history);

  }
  componentDidMount() {
    const {  onGetClientAddListing } = this.props;
   
    onGetClientAddListing(localStorage.getItem('userId'),localStorage.getItem('agentId'))
   
}
handleChange = (activeStatus, itemId) => {
    
};
  render() {
    const { addListings, loading } = this.props;
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Compare  | Realty Space</title>
          </MetaTags>
          <Container fluid>
             {/* <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Compare")}
            /> */}
            <Row>
              
              
              <Col xl="12">
              
			  <Row>
				<Col sm="12">
					 <Card className="mini-stats-wid"> 
                    <CardBody>
                      
                    <div className="row gy-2 gx-4  mb20">
                      <div className="col-sm-3 float-start">
                        <div className="col-sm-12">
                          <h4 className="compare-text">Compare Listings </h4>
                        </div>
                      </div>
                      <div className="col-sm-8 ">
                        {/* <div className="row float-end">
                          <div className="col-sm-auto">
                            
                            <Link to='/compare-not-match'><Button className="btn btn-primary w-md not-match">Not Match</Button></Link>
                          </div>

                          <div className="col-sm-auto">
                            <Link  to='/compare-match'><Button className="btn btn-primary w-md match">Match</Button></Link>
                          </div>
                        
                          <div className="col-sm-auto">
                            <Link  to='/compare-nice-to-have'><Button className="btn btn-primary w-md nice-to-have">Nice to have</Button></Link>
                          </div>
                          
                          
                        </div> */}
                      </div>
                    </div>
        <div className="clearfix"></div>
        
					  <div className="table-responsive">
					  	<table className="table mb-0 table">
                <thead>
                  <tr>
                  <th width="15%" className="txt-compare-head">Standard Preferences</th>
                  <th className="text-center">W4480580</th>
                  <th className="text-center">W4521018</th>
                  <th className="text-center">W4547688</th>
                  </tr>
                  <tr>
                  <th className="">Type of house</th>
                  <td className="text-center">Condo</td>
                  <td className="text-center">Detached</td>
                  <td className="text-center">Semi-detached</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th >Price</th>
                    <td className="text-center">$1,000,450</td>
                    <td className="text-center">$1,000,450</td>
                    <td className="text-center">$1,000,450</td>
                  </tr>

                  <tr>
                    <th># Bedrooms</th>
                    <td className="text-center">3</td>
                    <td className="text-center">4</td>
                    <td className="text-center">3</td>
                  </tr>
                  <tr>
                    <th># of bathrooms</th>
                    <td className="text-center">4</td>
                    <td className="text-center">4</td>
                    <td className="text-center">4</td>
                  </tr>
                  <tr>
                    <th>Square feet</th>
                    <td className="text-center">2350</td>
                    <td className="text-center">2350</td>
                    <td className="text-center">2350</td>
                  </tr>

                  <tr>
                  <th className="txt-compare-head">Custom Preferences</th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  </tr>
                  <tr>
                    <th>Need an open concept kitchen</th>
                    <td className="text-center"><i className="fas fa-check-circle  font-size-18 text-green"></i></td>
                    <td className="text-center"><i className="fas fa-times-circle font-size-18 text-red"></i></td>
                    <td className="text-center"><i className="fas fa-check-circle font-size-18 text-green"></i></td>
                  </tr>
             
                                
                </tbody>          
					  	</table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
			
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
addListing.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientAddListing:PropTypes.func,
  addListings:PropTypes.array,
  onGetClientAddListing:PropTypes.func,
  loading:PropTypes.object

}
const mapStateToProps = ({ addListing }) => (
  {
    addListings:addListing.addListings,
    loading:addListing.loading

  })
const mapDispatchToProps = dispatch => ({
  
  addClientAddListing: (data) => dispatch(addClientAddListing(data)),
  onGetClientAddListing:(clientId, agentId) => dispatch(getClientAddListing(clientId, agentId))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(addListing))
