import { CREATE_CLIENT_CHILD, CREATE_CLIENT_CHILD_FAIL, CREATE_CLIENT_CHILD_SUCCESS, CREATE_CLIENT_IMPORTANT_DATE, CREATE_CLIENT_IMPORTANT_DATE_FAIL, CREATE_CLIENT_IMPORTANT_DATE_SUCCESS, DELETE_CLIENT_IMPORTANT_DATE, DELETE_CLIENT_IMPORTANT_DATE_FAIL, DELETE_CLIENT_IMPORTANT_DATE_SUCCESS, GET_CLIENTS_DETAIL, GET_CLIENTS_DETAIL_FAIL, GET_CLIENTS_DETAIL_SUCCESS, GET_CLIENT_CHILD, GET_CLIENT_CHILD_FAIL, GET_CLIENT_CHILD_SUCCESS, GET_CLIENT_IMPORTANT_DATE, GET_CLIENT_IMPORTANT_DATE_FAIL, GET_CLIENT_IMPORTANT_DATE_SUCCESS, UPDATE_CLIENT_PROFILE, UPDATE_CLIENT_PROFILE_FAIL, UPDATE_CLIENT_PROFILE_SUCCESS,UPDATE_CLIENT_PRIMARY_CONTACT,UPDATE_CLIENT_PRIMARY_CONTACT_SUCCESS,UPDATE_CLIENT_PRIMARY_CONTACT_FAIL,UPDATE_CLIENT_SECONDARY_CONTACT,UPDATE_CLIENT_SECONDARY_CONTACT_SUCCESS,UPDATE_CLIENT_SECONDARY_CONTACT_FAIL } from "./actionTypes"

export const getClientDetail = clientId => (
  {
    type: GET_CLIENTS_DETAIL,
    clientId
  })
export const getClientDetailSuccess = clientDetail => ({
  type: GET_CLIENTS_DETAIL_SUCCESS,
  payload: clientDetail
})
export const getClientDetailFail = error => ({
  type: GET_CLIENTS_DETAIL_FAIL,
  payload: error
})

export const updateClientProfile = client => {
  return {
    type: UPDATE_CLIENT_PROFILE,
    payload: client,
  }
}

export const updateClientProfileSuccess = client => {
  return {
    type: UPDATE_CLIENT_PROFILE_SUCCESS,
    payload: client,
  }
}

export const updateClientProfileError = error => {
  return {
    type: UPDATE_CLIENT_PROFILE_FAIL,
    payload: error,
  }
}



export const updateClientPrimaryContact = client => {
  return {
    type: UPDATE_CLIENT_PRIMARY_CONTACT,
    payload: client,
  }
}

export const updateClientPrimaryContactSuccess = client => {
  return {
    type: UPDATE_CLIENT_PRIMARY_CONTACT_SUCCESS,
    payload: client,
  }
}

export const updateClientPrimaryContactError = error => {
  return {
    type: UPDATE_CLIENT_PRIMARY_CONTACT_FAIL,
    payload: error,
  }
}


export const updateClientSecondaryContact = client => {
  return {
    type: UPDATE_CLIENT_SECONDARY_CONTACT,
    payload: client,
  }
}

export const updateClientSecondaryContactSuccess = client => {
  return {
    type: UPDATE_CLIENT_SECONDARY_CONTACT_SUCCESS,
    payload: client,
  }
}

export const updateClientSecondaryContactError = error => {
  return {
    type: UPDATE_CLIENT_SECONDARY_CONTACT_FAIL,
    payload: error,
  }
}

export const addClientImportantDate = (importantdate, history) => {
  return {
    type: CREATE_CLIENT_IMPORTANT_DATE,
    payload: { importantdate, history },
  }
}

export const addClientImportantDateSuccess = importantdate => {
  return {
    type: CREATE_CLIENT_IMPORTANT_DATE_SUCCESS,
    payload: importantdate,
  }

}

export const addClientImportantDateError = error => {
  return {
    type: CREATE_CLIENT_IMPORTANT_DATE_FAIL,
    payload: error,
  }

}


export const getClientImportantDate = (clientId) => (
  {
    type: GET_CLIENT_IMPORTANT_DATE,
    clientId
  })

export const getClientImportantDateSuccess = importantdates => ({
  type: GET_CLIENT_IMPORTANT_DATE_SUCCESS,
  payload: importantdates,
})

export const getClientImportantDateFail = error => ({
  type: GET_CLIENT_IMPORTANT_DATE_FAIL,
  payload: error,
})

export const addClientChild = (child, history) => {
  return {
    type: CREATE_CLIENT_CHILD,
    payload: { child, history },
  }
}

export const addClientChildSuccess = child => {
  return {
    type: CREATE_CLIENT_CHILD_SUCCESS,
    payload: child,
  }

}

export const addClientChildError = error => {
  return {
    type: CREATE_CLIENT_CHILD_FAIL,
    payload: error,
  }

}

export const getClientChild = (clientId) => (
  {
    type: GET_CLIENT_CHILD,
    clientId
  })

export const getClientChildSuccess = childs => ({
  type: GET_CLIENT_CHILD_SUCCESS,
  payload: childs,
})

export const getClientChildFail = error => ({
  type: GET_CLIENT_CHILD_FAIL,
  payload: error,
})


export const deleteClientImportant = id => ({
  type: DELETE_CLIENT_IMPORTANT_DATE,
  payload: id,
})

export const deleteClientImportantSuccess = importantdate => ({
  type: DELETE_CLIENT_IMPORTANT_DATE_SUCCESS,
  payload: importantdate,
})

export const deleteClientImportantFail = error => ({
  type: DELETE_CLIENT_IMPORTANT_DATE_FAIL,
  payload: error,
})