import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header";

//apply base url for axios
const API_URL = "https://rscoreapi.azurewebsites.net/api/"


const token  = localStorage.getItem("token") ? localStorage.getItem("token") : '';

const obj = JSON.parse(localStorage.getItem("authUser"));
//console.log(obj);
const str = 'Bearer '+token;

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {'Authorization': str, 'Content-Type':'application/json', 'Access-Control-Allow-Origin': '*'}
})

//console.log(axiosApi);

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, {
    headers: { ...authHeader() },
    ...config
  }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, {
      headers: { ...authHeader() },
      ...config
    })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, data, {
      headers: { ...authHeader() },
      ...config
    })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, {
      headers: { ...authHeader() },
      ...config
    })
    .then(response => response.data);
}
