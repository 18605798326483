import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, apiError } from "./actions"
import { toast } from "react-toastify";
import {
  getLocalDataApi,
  postLogin
} from "../../../helpers/backend_helper"
import { getLocalDataSuccess } from "store/actions";

function* loginUser({ payload: { user, history } }) {
  try {
    //toast.dismiss();
      toast.loading("Please wait...")
      const response = yield call(postLogin, {
        userName: user.email,
        password: user.password,
      })
       
        const data = JSON.stringify(response)
        const dparse = JSON.parse(data);
    
        localStorage.setItem("token",dparse.resultData.bearerToken);
        localStorage.setItem("userId",dparse.resultData.uId);
        localStorage.setItem("agentId",dparse.resultData.createdBy);
        localStorage.setItem("authUser", data)
        yield put(loginSuccess(response))
        //const resdata = [];
        const resdata = yield call(getLocalDataApi);
        const newresdata = {}
        for (var i = 0, loc; i < resdata.length; i++) {
        loc = resdata[i];
        newresdata[ loc.localizationResourceKey] = loc.localizationResourceValue;
        }
        
        localStorage.setItem("localData",JSON.stringify(newresdata))
        //console.log(JSON.stringify(newresdata))
        //yield put(getLocalDataSuccess(resdata))
        history.push("/dashboard")
    }
catch (error) {
  toast.dismiss();

    yield put(apiError('Invalid User'))

  
}
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("token")
    localStorage.removeItem("userId")
    localStorage.removeItem("agentId")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}



function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
