import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"
import { getProjectDetail, getProjectGallary, postProjectInterest } from "../../store/projects/actions"
import { Link as ScrollLink } from "react-scroll";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Carousel, CarouselItem,
  CarouselControl,
  Nav,
  NavItem
} from "reactstrap"
import backIcon from "../../assets/img/backIcon.png"
class ProjectDetail extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
    this.handleInterestSubmit = this.handleInterestSubmit.bind(this)
    //this.handleGoBack = this.handleGoBack.bind(this);
  }
  onExiting() {
    this.animating = true
  }
  handleGoBack = (e) => {
    e.preventDefault();
    console.log("Go Back Triggered");
    const { history } = this.props;
    if (history.length !== "POP" ) {
      history.goBack();
    } else {
      history.push("/project"); 
    }
  };
  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return;
    const { projectMainImages } = this.props.projectDetails || {};
    const nextIndex =
      this.state.activeIndex === projectMainImages.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const { projectMainImages } = this.props.projectDetails || {};
    const nextIndex =
      this.state.activeIndex === 0
        ? projectMainImages.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }
  handleInterestSubmit(value) {
    //const {onGetProjectDetail} = this.props;
    this.props.postProjectInterest(value, this.props.history);
    //onGetProjectDetail(this.props.match.params.projectId);
  }

  componentDidMount() {
    const { match: { params }, onGetProjectDetail } = this.props;
    if (params && params.id && params.projectId) {
      onGetProjectDetail(params.projectId, localStorage.getItem('agentId'), localStorage.getItem('userId'));
    } else {
      //this.props.history.push('/my-project')
    }
  }
  renderInterestButton() {
    const { projectDetails } = this.props;
    return (
        <div className="d-grid text-center">
          <Formik
            enableReinitialize={true}
            initialValues={{
              projectId: projectDetails.id,
              guid: projectDetails.projectId,
              clientId: localStorage.getItem('userId'),
              agentId: localStorage.getItem('agentId')
            }}
            onSubmit={this.handleInterestSubmit}
          >
            <Form className="needs-validation">
              <button type="submit" className={projectDetails?.isInterestedByClient ? 'btn-lg btn btn-label btn-black disabled' : 'btn-lg btn btn-label btn-danger'}>
                <i className={projectDetails?.isInterestedByClient ? "bx bx-check label-icon" : "bx bx-heart label-icon" }></i>I AM INTERESTED
              </button>
            </Form>
          </Formik>
        </div>
      )
  }
  render() {
    const { projectDetails } = this.props;
    const mapStyles = {
      width: "100%",
      height: "250px"
    };
    const center = {
      lat: parseFloat(projectDetails?.projectSiteAddress?.latitude) || 0,
      lng: parseFloat(projectDetails?.projectSiteAddress?.longitude) || 0
    };
    let slides = [];
    if (projectDetails?.projectMainImages) {
      //console.log('hello');
      slides = projectDetails?.projectMainImages.map(item => {
        return (
          <Col style={{marginBottom:10}} sm="3" key={item.projectGalleryLink}>
            <img   src={item.projectGalleryLink} className="d-block img-fluid" />
          </Col>
        )
      })
    }

    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Project")}
              breadcrumbItem={this.props.t(projectDetails.projectName)}
            />
               <Row className="mb-20">
               <Col lg="3">

              <Link to="#" onClick={this.handleGoBack}><img src={backIcon} width='20px' /></Link>
                <div className="clearfix" style={{marginBottom:20}}></div>
               {projectDetails?.projectLogoLink && (
                <>
                  <img src={projectDetails.projectLogoLink} className="img-fluid" style={{width:100}} />
                </>
               )}
               </Col>
      <Col lg="6">
                <Nav tabs  className="projectTab projectNav nav-justified">
                      <NavItem>
                              <ScrollLink 
                                className={`nav-link`} 
                                activeClass="active"
                                to="documents"
                                spy={true}
                                smooth={true}
                                offset={-150}
                              >
                                <span className="d-none d-sm-block">Documents</span>
                              </ScrollLink>
                            </NavItem> 

                            <NavItem>
                              <ScrollLink 
                                className={`nav-link`} 
                                to="gallery"
                                 activeClass="active"
                                 spy={true}
                                smooth={true}
                                offset={-150}
                              >
                                <span className="d-none d-sm-block">Gallery</span>
                              </ScrollLink>
                            </NavItem> 

                            <NavItem>
                            { this.renderInterestButton() }
                            </NavItem>
                        </Nav>

                       
      </Col>
              </Row>
              <Row className="mb-20">
                <Col sm="12">
                {projectDetails?.projectCategoryGallery?.featuredImageGallery && (
                <>
                  <img src={projectDetails?.projectCategoryGallery?.featuredImageGallery[0]?.projectGalleryLink} className="img-fluid" />
                </>
               )}
                </Col>
              </Row>
            <Row>
              <Col lg="8">
                {projectDetails?.projectFeatures?.projectSummary && (
                  <>
                  <Card>
                    <div className="card-title-realty"><i className="mdi mdi-view-headline"></i> About</div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectSummary }}></div>

                    </CardBody>
                  </Card>
                 
                  </>
                )}
                
                <Card>
                  <div className="card-title-realty"><i className="far fa-list-alt "></i> Facts</div>
                  <CardBody>
                    <ul className="extra-details testest">

                      <li>
                        <div className="item-attr">Estimated Price Range</div>
                        <div className="item-property">${projectDetails.minPriceRange} - ${projectDetails.maxPriceRange}</div>
                      </li>
                      <li>
                        <div className="item-attr">Type </div>
                        <div className="item-property">{projectDetails.projectType}</div>
                      </li>
                      <li>
                        <div className="item-attr"># of Units</div>
                        <div className="item-property">{projectDetails.noOfUnits}</div>
                      </li>
                      <li>
                        <div className="item-attr"># Stories</div>
                        <div className="item-property">{projectDetails.noOfStories}</div>
                      </li>
                      <li>
                        <div className="item-attr">Compl. Month / Year </div>
                        <div className="item-property">{projectDetails.complitionMonth} / {projectDetails.complitionYear}</div>
                      </li>
                      <li>
                        <div className="item-attr">Min Bedroom / Max Bedroom </div>
                        <div className="item-property">{projectDetails.minBed} / {projectDetails.maxBed}</div>
                      </li>

                    </ul>

                  </CardBody>
                </Card>
               
                {projectDetails?.projectFeatures?.projectHighlights && (
                  <>
                  <Card>
                    <div className="card-title-realty"><i className="mdi mdi-view-headline"></i> Highlights</div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectHighlights }}></div>

                    </CardBody>
                  </Card>
                
                  </>
                )}
               
               
              {Array.isArray(projectDetails?.projectDocuments) && projectDetails.projectDocuments.length > 0 && (
                  <>
                    <Card id="documents">
                      <div className="card-title-realty">
                        <i className="mdi mdi-view-headline"></i> Documents
                      </div>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="align-middle mb-0 table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Document</th>
                                <th>Type</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectDetails?.projectDocuments.map((item, index) => (
                                <tr key={item.documentId || index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{item.projectDocumentTitle}</td>
                                  <td>{item.projectDocumentTypeId}</td>
                                  <td>
                                    <a href={item.projectDocumentLink} target="_blank" rel="noreferrer">View</a>
                                   
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>

                    
                    </>
                  )}
               
                 {projectDetails?.projectFeatures?.projectPriceFee && (
                   <>
                  <Card>
                    <div className="card-title-realty"><i className="mdi mdi-view-headline"></i> Price & Fees</div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectPriceFee }}></div>

                    </CardBody>
                  </Card>
                 
                  </>
                )}
                

                  {projectDetails?.projectFeatures?.projectDepositStructure && (
                    <>
                  <Card>
                    <div className="card-title-realty"><i className="mdi mdi-view-headline"></i> Deposite Structure</div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectDepositStructure }}></div>

                    </CardBody>
                  </Card>
                 
                  </>
                )}
            

                  {projectDetails?.projectFeatures?.projectInternationalDepositStructure && (
                      <>
                  <Card>
                    <div className="card-title-realty"><i className="mdi mdi-view-headline"></i> International Deposit Structure</div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectInternationalDepositStructure }}></div>

                    </CardBody>
                  </Card>
                  
                   </>
                )}
               

                {projectDetails?.projectFeatures?.projectCurrentIncentive && (
                  <>
                  <Card>
                    <div className="card-title-realty"><i className="mdi mdi-view-headline"></i> Current Incentive</div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectCurrentIncentive }}></div>

                    </CardBody>
                  </Card>
                   
                    </>
                )}
                {slides.length > 0 && (
                  <>
                  <div id="gallery" style={{ marginBottom: 20 }}>
                    <Row
                      activeIndex={this.state.activeIndex}
                      next={this.next}
                      previous={this.previous}
                    >
                      {slides}
                     
                    </Row>
                  </div>

                  
                  </>
                )}
               { this.renderInterestButton() }

               
              </Col>
              <Col lg="4">
                {projectDetails?.projectSiteAddress?.fullAddress && (
                  <Card>
                    <div className="card-title-realty"><i className=" bx bx-map-alt"></i> Location</div>
                    <CardBody>
                      <div className="contact-map">
                        <div className="map-container">
                          <Map
                            google={google}
                            zoom={15}
                            initialCenter={center}
                          >
                            <Marker position={center} />
                          </Map>
                        </div>

                      </div>
                      <div className="map-block-address">
                        <p>{projectDetails?.projectSiteAddress?.fullAddress}</p>

                      </div>
                    </CardBody>
                  </Card>
                )}
                   {Array.isArray(projectDetails?.projectLinks) && projectDetails.projectLinks.length > 0 && (
                    <Card>
                      <div className="card-title-realty">
                        <i className="mdi mdi-view-headline"></i> Links
                      </div>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="align-middle mb-0 table">
                           
                            <tbody>
                              {projectDetails?.projectLinks.map((item, index) => (
                                <tr key={item.id || index}>
                                
                                  <td><a href={item.projectLinkUrl} target="_blank" rel="noreferrer">{item.projectLinkTitle}</a></td>
                                 
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                {projectDetails?.projectFeatures?.projectDeveloper && (
                  <Card>
                    <div className="card-title-realty"><i className=" dripicons-ticket"></i> About The Builder </div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectDeveloper }}></div>

                    </CardBody>
                  </Card>
                )}
                {projectDetails?.projectFeatures?.projectOverview && (
                  <>
                  <Card>
                    <div className="card-title-realty"><i className=" dripicons-ticket"></i> Amenities</div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectOverview }}></div>

                    </CardBody>
                  </Card>

                
                  </>
                )}
              {projectDetails?.projectFeatures?.projectFeatureFinishes && (
                <>
                  <Card>
                    <div className="card-title-realty"><i className="mdi mdi-view-headline"></i> Features & Finishes</div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectFeatureFinishes }}></div>

                    </CardBody>
                  </Card>
                  
                   </>
                )}
                 {projectDetails?.projectFeatures?.projectLocationNeighbourhood && (
                   <>
                  <Card>
                    <div className="card-title-realty"><i className="mdi mdi-view-headline"></i> Location Neighbourhood</div>
                    <CardBody>
                      <div className="summery" dangerouslySetInnerHTML={{ __html: projectDetails?.projectFeatures?.projectLocationNeighbourhood }}></div>

                    </CardBody>
                  </Card>
                  
                   </>
                )}
                { this.renderInterestButton() }
              </Col>
            </Row>





          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ProjectDetail.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
  projectDetails: PropTypes.object,
  postProjectInterest: PropTypes.func
}
const mapStateToProps = ({ projects }) => (
  {
    projectDetails: projects.projectDetails,

  })

const mapDispatchToProps = dispatch => ({
  onGetProjectDetail: (projectId, agentId, clientId) => dispatch(getProjectDetail(projectId, agentId, clientId)),
  postProjectInterest: (data) => dispatch(postProjectInterest(data)),
  onGetProjectGallery: (projectId) => dispatch(getProjectGallary(projectId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectDetail)))
