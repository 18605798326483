import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import {postHomePref, getHomePref, updateHomePref } from "../../store/prefrences/actions"
import prefImg from "../../assets/images/rsicons/prefrence.png"

import editIcon from "../../assets/images/icons/edit2.png";
import deleteIcon from "../../assets/images/icons/bin.png";
import DeleteModal from "../../components/Common/DeleteModal";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Button,
    Collapse,
    Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardText,
  CardTitle
} from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";


class homePref extends Component {
    constructor(props) {
        super(props)
        this.state = {
          homeprefrence:''
        }

        this.handleHomePrefSubmit = this.handleHomePrefSubmit.bind(this);
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
      this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
      this.setState(prevState => ({
        deleteModal: !prevState.deleteModal,
      }));
    };
    onClickDelete = (homeprefrence) => {
      this.setState({ homeprefrence: homeprefrence });
      this.setState({ deleteModal: true });
    };
  
    handleDeleteHomePref = () => {
      const { onDeleteHomePref } = this.props;
      const { homeprefrence } = this.state;
    
      if (homeprefrence.id !== undefined) {
        onDeleteHomePref(homeprefrence);
        this.setState({ deleteModal: false });
      }
    };
    toggle() {
      this.setState(prevState => ({
        isRight: !prevState.isRight
      }));
    }
    handleHomePrefSubmit(value){
      //console.log(value)
      this.props.postHomePref(value, this.props.history);
  
    }
    componentDidMount() {
      const { onGetHomePref } = this.props;
      onGetHomePref(localStorage.getItem('userId'));
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { homeprefrences } = this.props;
    if (!isEmpty(homeprefrences) && size(prevProps.homeprefrences) !== size(homeprefrences)) {
      this.setState({ homeprefrences: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ homeprefrence: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const homeprefrence = arg;
    this.setState({
      homeprefrence: {
        id: homeprefrence.id,
        clientBuyerHomePreferenceAnswerId: homeprefrence.clientBuyerHomePreferenceAnswerId,
        homePreferenceAnswerId: homeprefrence.homePreferenceAnswerId,
        homePreferenceTitle:homeprefrence.homePreferenceTitle,
        answersOption:homeprefrence.answersOption,
        preference:homeprefrence.preference,
        clientId: homeprefrence.clientId,
        isActive:homeprefrence.isActive
      },
      isEdit: true,
    });
    this.toggle();
  };
    render() {
        const {homeprefrences } = this.props;
        const { isEdit, deleteModal } = this.state;
        const homeprefrence = this.state.homeprefrence;
        return (
            <React.Fragment>
              {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null} 
              <ToastContainer autoClose={2000} />
              <DeleteModal
              show={deleteModal}
              onDeleteClick={this.handleDeleteHomePref}
              onCloseClick={() => this.setState({ deleteModal: false })}
            />
                <div className="page-content">
          <MetaTags>
            <title>Preferences | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Home Preferences")}
            />

                <Row>
                    <Col sm="12">
                      <Card className="mini-stats-wid" style={{backgroundColor:'#eff7ff',fontFamily:'Helvetica '}}> 
                        <CardBody>
                          <CardTitle>Welcome to your home preferences page! </CardTitle>
                          <p style={{fontFamily:'Helvetica '}}>
                          Here, you can customize your ideal living space to suit your needs.  Feel free to add any additional preferences that will be used to match 
                          properties of interest against your criteria, helping you find your perfect home. Let&apos;s get started
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                </Row>


                <Row>
                    <Col sm="12">
                    <Formik
                  enableReinitialize={true}
                  initialValues={{
                    homePreferenceTitle: (this.state && this.state.homePreferenceTitle) || "",
                    answersOption: (this.state && this.state.answersOption) || "",
                    clientId: localStorage.getItem('userId')

                  }}
                  validationSchema={Yup.object().shape({
                    homePreferenceTitle: Yup.string().required("This is Required"),
                    answersOption: Yup.string().required("This is Required"),
                    
                  })}

                  onSubmit={this.handleHomePrefSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card className="mini-stats-wid"> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                   <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="preference">
                              {this.props.t("Type of house")}
                            </Label>
                            <Field as="select" name="houseType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Condo">Condo</option>
                               
                            </Field>
                          </FormGroup>
                        </Col>  
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="homePreferenceTitle">
                              {this.props.t("Min. price")}
                            </Label>
                            <Field
                              name="homePreferenceTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.homePreferenceTitle && touched.homePreferenceTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="homePreferenceTitle"
                            />
                                <ErrorMessage
                                  name="homePreferenceTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("Max price")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("Square feet")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("# Bedrooms")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col> 
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("# of bathrooms")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col> 
                       <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 10}} type="submit" className="btn btn-primary w-md">Save</button></div></Col>
                    </Row>
                  
             
             </CardBody>
                </Card>
                
                </Form>
                  )}
                </Formik>
                    </Col>
                </Row>

               



      <Row>
				<Col sm="12">
					 <Card className="mini-stats-wid"> 
                    <CardBody>
                    <Col sm="3">  <div className="clearfix"><button style={{ marginTop: 10}} type="button" className="btn btn-custom w-lg">Create custom preferences</button></div></Col> 
					

                    <Col lg="12" style={{marginTop:30}}>
        
        <div className="">
          <table className="table table-striped">
            
            <tbody>
                      <tr>
                          <td>Need an open concept kitchen</td>
                         
                          <td>
                                  <div className="d-flex gap-3">
                                  <Link className="text-purpol" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></Link> {" "} <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="edittooltip"></i></Link>
                                  </div>
                                  </td>
                      </tr>
                      </tbody>
          </table>


        </div>
        </Col>
					</CardBody>
					</Card>
				</Col>
			</Row>
   
            </Container>
            </div>
            </React.Fragment>
        )
    }
}
homePref.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    postHomePref:PropTypes.func,
    onGetHomePref:PropTypes.func,
    homeprefrences:PropTypes.array,
    loading:PropTypes.object,
    onDeleteHomePref:PropTypes.func,
    onUpdateHomePref:PropTypes.func


}

const mapStateToProps = ({ Prefrences }) => (
    {
      homeprefrences : Prefrences.homeprefrences,
      loading:Prefrences.loading

    })

const mapDispatchToProps = dispatch => ({
    postHomePref: (data) => dispatch(postHomePref(data)),
    onGetHomePref: (clientId) => dispatch(getHomePref(clientId))
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(homePref))