import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import { withTranslation } from "react-i18next"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"

import primg1 from "../../assets/images/no-img.jpg"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getProjects } from "../../store/projects/actions"
import axios from "axios";

class Project extends Component {
  constructor(props) {
  super(props);
  this.state = {
    selectedStatus: '0',
    projectStatus:[],
  }
}
  componentDidMount() {
    const { onGetProject } = this.props;
    onGetProject(localStorage.getItem('agentId'));

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    
      axios.get('https://rscoreapi.azurewebsites.net/api/ProjectStatus/all', { headers: { Authorization: str } })
        .then(res => {
          const projectStatus = res.data;
          this.setState({ projectStatus });
        })

}
handleStatusChange = (event) => {
  this.setState({ selectedStatus: event.target.value });
};
getFilteredProjects = () => {
  const { selectedStatus } = this.state;
  const { projects } = this.props;
  
  console.log("Selected Status:", selectedStatus);
  console.log("Projects:", projects);
  
  return selectedStatus === '0'
    ? projects
    : projects.filter(project => {
        return String(project.projectStatus) === selectedStatus;
      });
};
  render() {
    console.log(localStorage.getItem('authUser'));
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const { projects } = this.props;
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <div className="page-content">
          <MetaTags>
            <title>Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
          {/* <Breadcrumbs
              title="Projects"
              breadcrumbItem="Projects"
            /> */}
            <h4 style={{width:'100%', textTransform:'uppercase'}}>Currently Selling


              <span className="float-end mb-20">
              <select name="status"
               value={this.state.selectedStatus} 
               onChange={this.handleStatusChange} 
              className="form-select">
                          <option value="0">Select Status...</option>
                          {
                            this.state.projectStatus
                              .map(projectStat =>
                                <option key={projectStat.id} value={projectStat.id}>{projectStat.projectStatusTitle}</option>
                              )
                          }
                        </select>

              </span>
            </h4>
            <div className="clearfix"></div>

<Row>
{this.getFilteredProjects().map((project, projectkey) => (
  <Col sm="3" key={"_note_" + projectkey}>
    <div className="product">
      <div className="project-satatus">
          <span>{localization["ProjectStatus_"+ project.projectStatus]}</span>
      </div>
    <Link to={"/project-detail/" + project.id + "/" + project.projectId}>
        <div className="pro-img minHeight">
        {project.projectMainImage != null 
           ? <img src={project?.projectMainImage?.projectGalleryLink} style={{width:300}} className="img-fluid" />
           : <img src={primg1} style={{width:300}} className="img-fluid" />
        }
        </div>
      </Link>
      <div className="bg-transparent border-top card-footer"><div className="contact-links d-flex"><div className="flex-fill font-size-14">{project.city}</div><div className="float-end"><Link to={"/project-detail/" + project.id + "/" + project.projectId}> {project.projectName} </Link></div></div></div>
        
    </div>

  </Col>
))}
 
</Row>





           
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Project.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  onGetProject:PropTypes.func,
  projects:PropTypes.array,
  loading:PropTypes.object
}

const mapStateToProps = ({ projects }) => (
    {
        projects:projects.projects,
        loading:projects.loading
  
    })

    const mapDispatchToProps = dispatch => ({
        
        onGetProject:(agetnId) => dispatch(getProjects(agetnId))
       
      });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Project))
