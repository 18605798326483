//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_CLIENT_LOGIN = "user/login"
export const GET_LOCAL_DATA_URL = "LocalizationResource/all"

export const POST_CLIENT_MESSAGE_API_URL = "ClientMessage"

export const GET_CLIENT_MESSAGE_API_URL = "ClientMessage/allClient"

export const POST_CLIENT_DOCUMENT_API_URL = "ClientDocument"

export const GET_CLIENT_DOCUMENT_API_URL = "ClientDocument/allClient"

export const GET_CLIENT_PREF_QUEST_API_URL  ="HomePreferenceAnswer/allforagenttype"

export const GET_ALL_PROJECT_API_URL  ="AgentClient/allagentbrokerassignedprojectsfull"

export const GET_PROJECT_DETAIL_API_URL = "AgentClient/selectedagentbrokerassignedprojectsfull"

export const POST_PROJECT_INTEREST_API_URL = "ClientBuyerInterestedProject"

export const GET_PROJECT_INTEREST_API_URL = "ClientBuyerInterestedProject/all"

export const POST_CLIENT_SELLER_SHOWING_API_URL = "ClientSellerShowing"
export const GET_CLIENT_SELLER_SHOWING_API = "ClientSellerShowing/all"
export const DELETE_CLIENT_SHOWING_API = "ClientSellerShowing/"
export const UPDATE_CLIENT_SELLER_SHOWING_API_URL = "ClientSellerShowing"

export const GET_CLIENT_SELLER_LISTING_API = "ClientListing/all"
export const POST_CLIENT_APPOINTMENT_API =  "ClientAppointment"
export const GET_CLIENT_APPOINTMENT_API = "ClientAppointment/all"
export const DELETE_CLIENT_APPOINTMENT_API = "ClientAppointment/"


export const POST_BUYER_DESTINATION_API = "ClientBuyerImportantDestinationExt/bulkadd"


export const GET_PROJECT_FEATURE_API_URL ="ProjectFeature/all"
export const GET_PROJECT_LINK_API_URL       = "ProjectLink/all"
export const GET_PROJECT_GALLERY_API_URL = "ProjectGallery/allproject"




export const POST_CLIENT_ADD_LISTING_API = "AgentClientPotentialListing"
export const GET_CLIENT_ADD_LISTING_API = "AgentClientPotentialListing/all"


export const DELETE_CLIENT_DOCUMENT_API ="ClientDocument/"
export const UPDATE_CLIENT_DOCUMENT_API ="ClientDocument"

export const UPDATE_CLIENT_APPOINTMENT_API = "ClientAppointment"

export const GET_CLIENT_DETAIL_API =        "ClientExt/"

export const  CREATE_CLIENT_IMPORTANT_DATE_URL ="ClientImportantDate"
export const  GET_CLIENT_IMPORTANT_DATE_URL ="ClientImportantDate/all"
export const DELETE_CLIENT_IMPORTANT_DATE_URL = "ClientImportantDate/"
export const  CREATE_CLIENT_CHILD_URL ="ClientChild"
export const  GET_CLIENT_CHILD_URL ="ClientChild/all"
export const UPDATE_CLIENT_PROFILE_API = "ClientExt"
export const UPDATE_CLIENT_PRIMARY_CONTACT_API = "ClientContact/update_client_primary_contact"
export const UPDATE_CLIENT_SECONDARY_CONTACT_API = "ClientContact/update_client_secondary_contact"

export const CREATE_NEW_CLIENT_URL = "ClientExt"

export const GET_CLIENT_UPCOMING_ACTIVITIES_API = "AgentClient/allagentclientupcomingactivities"
export const GET_CLIENT_RECENT_ACTIVITIES_API = "AgentClient/allagentclientrecentactivities"


export const POST_HOME_DESTINATION_API = "ClientBuyerHomePreferenceAnswer"
export const  GET_HOME_DESTINATION_API = "ClientBuyerHomePreferenceAnswer/all"
export const UPDATE_HOME_DESTINATION_API = "ClientBuyerHomePreferenceAnswer"
export const  DELETE_HOME_DESTINATION_API = "ClientBuyerHomePreferenceAnswer/"


export const POST_IMP_DESTINATION_API = "ClientBuyerImportantDestination"
export const GET_IMP_DESTINATION_API = "ClientBuyerImportantDestination/all"



export const CREATE_APPOINTMENT_LOCATION_TYPE_URL =  "AppointmentLocationType"
export const GET_APPOINTMENT_LOCATION_TYPE_URL =  "AppointmentLocationType/allforagent"
export const UPDATE_APPOINTMENT_LOCATION_TYPE_URL =  "AppointmentLocationType"
export const DELETE_APPOINTMENT_LOCATION_TYPE_URL =  "AppointmentLocationType/"


export const CREATE_APPOINTMENT_PRIORITY_URL =  "AppointmentPriority"
export const GET_APPOINTMENT_PRIORITY_URL =  "AppointmentPriority/allforagent"
export const UPDATE_APPOINTMENT_PRIORITY_URL =  "AppointmentPriority"
export const DELETE_APPOINTMENT_PRIORITY_URL =  "AppointmentPriority/"

export const CREATE_APPOINTMENT_TYPE_URL =  "AppointmentType"
export const GET_APPOINTMENT_TYPE_URL =  "AppointmentType/allforagent"
export const UPDATE_APPOINTMENT_TYPE_URL =  "AppointmentType"
export const DELETE_APPOINTMENT_TYPE_URL =  "AppointmentType/"


export const CREATE_CONTACT_LEVEL_URL =  "ContactLevel"
export const GET_CONTACT_LEVEL_URL =  "ContactLevel/allforagent"
export const UPDATE_CONTACT_LEVEL_URL =  "ContactLevel"
export const DELETE_CONTACT_LEVEL_URL =  "ContactLevel/"

export const CREATE_CONTACT_STATUS_URL =  "ContactStatus"
export const GET_CONTACT_STATUS_URL =  "ContactStatus/allforagent"
export const UPDATE_CONTACT_STATUS_URL =  "ContactStatus"
export const DELETE_CONTACT_STATUS_URL =  "ContactStatus/"

export const CREATE_CONTACT_TYPE_URL =  "ContactType"
export const GET_CONTACT_TYPE_URL =  "ContactType/allforagent"
export const UPDATE_CONTACT_TYPE_URL =  "ContactType"
export const DELETE_CONTACT_TYPE_URL =  "ContactType/"


export const CREATE_LEAD_CLASSIFICATION_URL =  "LeadClassification"
export const GET_LEAD_CLASSIFICATION_URL =  "LeadClassification/allforagent"
export const UPDATE_LEAD_CLASSIFICATION_URL =  "LeadClassification"
export const DELETE_LEAD_CLASSIFICATION_URL =  "LeadClassification/"


export const CREATE_LEAD_SOURCE_URL =  "LeadSource"
export const GET_LEAD_SOURCE_URL =  "LeadSource/allforagent"
export const UPDATE_LEAD_SOURCE_URL =  "LeadSource"
export const DELETE_LEAD_SOURCE_URL =  "LeadSource/"


export const CREATE_LEAD_STATUS_URL =  "LeadStatus"
export const GET_LEAD_STATUS_URL =  "LeadStatus/allforagent"
export const UPDATE_LEAD_STATUS_URL =  "LeadStatus"
export const DELETE_LEAD_STATUS_URL =  "LeadStatus/"

export const CREATE_LEAD_TYPE_URL =  "LeadType"
export const GET_LEAD_TYPE_URL =  "LeadType/allforagent"
export const UPDATE_LEAD_TYPE_URL =  "LeadType"
export const DELETE_LEAD_TYPE_URL =  "LeadType/"


export const CREATE_NOTE_TYPE_URL =  "NoteType"
export const GET_NOTE_TYPE_URL =  "NoteType/allforagent"
export const UPDATE_NOTE_TYPE_URL =  "NoteType"
export const DELETE_NOTE_TYPE_URL =  "NoteType/"


export const CREATE_PROJECT_DOC_TYPE_URL =  "ProjectDocumentType"
export const GET_PROJECT_DOC_TYPE_URL =  "ProjectDocumentType/allforagent"
export const UPDATE_PROJECT_DOC_TYPE_URL =  "ProjectDocumentType"
export const DELETE_PROJECT_DOC_TYPE_URL =  "ProjectDocumentType/"


export const CREATE_PROJECT_STATUS_URL =  "ProjectStatus"
export const GET_PROJECT_STATUS_URL =  "ProjectStatus/allforagent"
export const UPDATE_PROJECT_STATUS_URL =  "ProjectStatus"
export const DELETE_PROJECT_STATUS_URL =  "ProjectStatus/"

export const CREATE_PROJECT_TYPE_URL =  "ProjectType"
export const GET_PROJECT_TYPE_URL =  "ProjectType/allforagent"
export const UPDATE_PROJECT_TYPE_URL =  "ProjectType"
export const DELETE_PROJECT_TYPE_URL =  "ProjectType/"

export const CREATE_SERVICE_TYPE_URL =  "ServiceType"
export const GET_SERVICE_TYPE_URL =  "ServiceType/allforagent"
export const UPDATE_SERVICE_TYPE_URL =  "ServiceType"
export const DELETE_SERVICE_TYPE_URL =  "ServiceType/"


export const CREATE_TASK_PRIORITY_URL =  "TaskPriority"
export const GET_TASK_PRIORITY_URL =  "TaskPriority/allforagent"
export const UPDATE_TASK_PRIORITY_URL =  "TaskPriority"
export const DELETE_TASK_PRIORITY_URL =  "TaskPriority/"


export const CREATE_TASK_REMINDER_URL =  "TaskReminder"
export const GET_TASK_REMINDER_URL =  "TaskReminder/allforagent"
export const UPDATE_TASK_REMINDER_URL =  "TaskReminder"
export const DELETE_TASK_REMINDER_URL =  "TaskReminder/"

export const CREATE_TASK_STATUS_URL =  "TaskStatus"
export const GET_TASK_STATUS_URL =  "TaskStatus/allforagent"
export const UPDATE_TASK_STATUS_URL =  "TaskStatus"
export const DELETE_TASK_STATUS_URL =  "TaskStatus/"


export const CREATE_TASK_TYPE_URL =  "TaskType"
export const GET_TASK_TYPE_URL =  "TaskType/allforagent"
export const UPDATE_TASK_TYPE_URL =  "TaskType"
export const DELETE_TASK_TYPE_URL =  "TaskType/"

export const GET_LISTING_TYPE_API_URL = 'ListingType/all'

export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"
export const ADD_NEW_PROJECT = "/add/product"
export const UPDATE_PROJECT = "/update/product"
export const DELETE_PROJECT = "/delete/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"