import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { isEmpty, map, size } from "lodash";
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import img1 from "../../assets/images/sidebar/img1.jpg"
import primg1 from "../../assets/images/no-img.jpg"
import primg2 from "../../assets/images/project/img2.jpg"
import primg3 from "../../assets/images/project/img3.jpg"
import primg4 from "../../assets/images/project/img4.jpg"

import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
import { getClientRecentActivities, getClientUpcomingActivities, getProjects } from "../../store/actions"
import Moment from 'react-moment';
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {
    const { onGetClientRecentActivities, onGetClientUpcomingActivities, onGetProject } = this.props;
    
    onGetClientRecentActivities(localStorage.getItem('userId'), localStorage.getItem('agentId'));
    onGetClientUpcomingActivities(localStorage.getItem('userId'), localStorage.getItem('agentId'));
    onGetProject(localStorage.getItem('agentId'));
}


  render() {
    const { uactivities, ractivities, projects } = this.props;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
          {/* <Row>
              <Col md="4">
                  <Card>
                  <CardBody>
                  <div className="mb-4 card-title float-start">Messages</div>
                  <div className="clearfix"></div>
                  <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div className="event-timeline-dot">
                <img src={appointment} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                      9:00 AM
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>Breakfast with Jay </div>
                  </div>
                </div>
              </li>

              <li className="event-list">
                <div className="event-timeline-dot">
                <img src={call} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                      1:00 PM 
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div id="activitytext">
                    Call Steven 416-123-1789
                    </div>
                  </div>
                </div>
              </li>
              <li className="event-list active">
                <div className="event-timeline-dot">
                <img src={activiti} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                    2:00-4:00 pm
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>At 123 Magnum street, Toronto</div>
                  </div>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                <img src={appointment} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                     
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>Marriage anniversary for Charles & Diana</div>
                  </div>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                <img src={todo} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                     
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div id="activitytext">
                    Prepare listing presentation for Clarke
                    </div>
                  </div>
                </div>
              </li>
              <li></li>
            </ul>
            
                  </CardBody>
                  </Card>                  

              </Col>

              <Col md="4">
                  <Card>
                  <CardBody>
                  <div className="mb-4 card-title float-start"> Upcoming Appointments</div>
                  <div className="clearfix"></div>
                  <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div className="event-timeline-dot">
                <img src={appointment} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                      9:00 AM
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>Breakfast with Jay </div>
                  </div>
                </div>
              </li>

             
             
            
            </ul>
            
                  </CardBody>
                  </Card>                  

              </Col>

              <Col md="4">
                  <Card>
                  <CardBody>
                  <div className="mb-4 card-title float-start"> Documents</div>
                  <div className="clearfix"></div>
                  <ul className="verti-timeline list-unstyled">
              

             
             
            
                </ul>
            
                  </CardBody>
                  </Card>                  

              </Col>
              
              
             
            </Row> */}
            <Row>
               <Col sm="6">
               
            <Card> 
                    <CardBody>
                    <h5 className="card-title">Upcoming Activities</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								<th>Date</th>
								<th>Time</th>
                <th>Type</th>
                <th>Action</th>
                
							  </tr>
							</thead>
							<tbody>
              {/* {map(uactivities, (uactivitie, ukey) => (
                <tr key={"_alist_" + ukey}>
                    <td><Moment format="D MMM YY">{uactivitie.activityDate}</Moment></td>
                    <td><Moment format="hh:mm A">{uactivitie.activityRefStartTime}</Moment></td>
                    <td>{uactivitie.clientName}</td>
                    <td>{uactivitie.activityType}</td>
                    <td></td>
                  </tr>
                              ))} */}
                <tr>
                  <td>8th March, 2024</td>
                  <td>3:00 pm</td>
                  <td>Meeting</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>11th March, 2024</td>
                  <td>4:30 pm</td>
                  <td>Open House</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>14th March, 2024</td>
                  <td>11:30 pm</td>
                  <td>Showing</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>19th March, 2024</td>
                  <td>11:30 pm</td>
                  <td>Closing</td>
                  <td>View</td>
                </tr>
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>                  

            </Col>

            <Col sm="6">
               
               <Card> 
                       <CardBody>
                       <h5 className="card-title">Current Interactions</h5>
             <div className="table-responsive">
                 <table className="table mb-0 table">
                 <thead>
                   <tr>
                   <th>Date</th>
                   <th>Time</th>
                   <th>Type</th>
                   <th>Action</th>
                   
                   </tr>
                 </thead>
                 <tbody>
                 <tr>
                  <td>1th March, 2024</td>
                  <td>1:30 pm</td>
                  <td>Message</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>1th March, 2024</td>
                  <td>4:30 pm</td>
                  <td>Listings</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>1th March, 2024</td>
                  <td>11:30 pm</td>
                  <td>Document</td>
                  <td>View</td>
                </tr>
                 </tbody>
                 </table>
               </div>
             </CardBody>
             </Card>                  
   
               </Col>
                            

            </Row>

            <h4>New Projects</h4>
            <Row>
                {map(projects, (project, projectkey) => (
                  <Col sm="3" key={"_note_" + projectkey}>
                    <div className="product">
                    <div className="project-satatus">
                      <span>{localization["ProjectStatus_"+ project.projectStatus]}</span>
                  </div>
                    <Link to={"/project-detail/" + project.id + "/" + project.projectId}>
                        <div className="pro-img minHeight">
                        {project.projectMainImage != null 
                          ? <img src={project?.projectMainImage?.projectGalleryLink} style={{width:300}} className="img-fluid" />
                          : <img src={primg1} style={{width:300}} className="img-fluid" />
                        }
                        </div>
                    </Link>
                      <div className="bg-transparent border-top card-footer"><div className="contact-links d-flex"><div className="flex-fill font-size-14">{project.city}</div><div className="float-end"><Link to={"/project-detail/" + project.id + "/" + project.projectId}> {project.projectName} </Link></div></div></div>
                        
                    </div>

                  </Col>
                ))}
              </Row>     
        
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Dashboard.propTypes = {
  t: PropTypes.any,
  onGetClientLists:PropTypes.func,
  onGetClientRecentActivities:PropTypes.func,
  onGetClientUpcomingActivities:PropTypes.func,
  uactivities:PropTypes.array,
  ractivities:PropTypes.array,
  onGetProject:PropTypes.func,
  projects:PropTypes.array,
}
const mapStateToProps = ({ Dashboard, projects }) => (
  {
      
      uactivities:Dashboard.uactivities,
      ractivities:Dashboard.ractivities,
      projects:projects.projects

  })
const mapDispatchToProps = dispatch => ({
  onGetClientLists: agentID => dispatch(getClientList(agentID)),
  onGetClientRecentActivities: (clientId,agentId) => dispatch(getClientRecentActivities(clientId,agentId)),
  onGetClientUpcomingActivities: (clientId,agentId) => dispatch(getClientUpcomingActivities(clientId,agentId)),
  onGetProject:(agetnId) => dispatch(getProjects(agetnId))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard))
