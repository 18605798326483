import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_PROJECT_FEATURE_ERROR,
  GET_PROJECT_FEATURE_SUCCESS,
  GET_PROJECT_GALLERY_ERROR,
  GET_PROJECT_GALLERY_SUCCESS,
  GET_PROJECT_INTEREST,
  GET_PROJECT_INTEREST_FAIL,
  GET_PROJECT_INTEREST_SUCCESS,
  GET_PROJECT_LINK_ERROR,
  GET_PROJECT_LINK_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  projects: [],
  projectDetails: {},
  interestDetail: {},
  error: {},
  projectFeature: {},
  links: [],
  images: [],
  loading: true,
}

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        loading: true,
      }
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        loading: false,
      }

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetails: action.payload,
      }

    case GET_PROJECT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_PROJECT_INTEREST_SUCCESS:
      return {
        ...state,
        interestDetails: action.payload,
      }

    case GET_PROJECT_INTEREST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_FEATURE_SUCCESS:
      return {
        ...state,
        projectFeature: action.payload,
      }

    case GET_PROJECT_FEATURE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_LINK_SUCCESS:
      return {
        ...state,
        links: action.payload,
      }

    case GET_PROJECT_LINK_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_GALLERY_SUCCESS:
      return {
        ...state,
        images: action.payload,
      }

    case GET_PROJECT_GALLERY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default projects
