import { combineReducers } from "redux"
import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"

import Messages from "./message/reducer"
import Documents from "./document/reducer"
import Prefrences from "./prefrences/reducer"
import Showings from "./showing/reducer"
import Appointments from "./appointment/reducer"

import addListing from "./addlisting/reducer"
import calendar from "./calendar/reducer"
import projects from "./projects/reducer"
import Dashboard from "./dashboard/reducer";
import DashboardSaas from "./dashboard-saas/reducer";

import AppoinmentObject from "./settings/Appointment/reducer"
import localization from "./localization/reducer"
import Profile from "./profile/reducer"
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Messages,
  Documents,
  Prefrences,
  Showings,
  Appointments,
  addListing,
  calendar,
  projects,
  Dashboard,
  DashboardSaas,
  AppoinmentObject,
  localization,
  Profile
})
export default rootReducer
