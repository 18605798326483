import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
  Label
} from "reactstrap"
import primg1 from "../../assets/images/project/img1.jpg"
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types";
import deleteIcon from "../../assets/images/icons/bin.png";
import axios from "axios";
import Moment from "react-moment";
class myTransection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transaction:'',
      transactions:[],
      existingSchedules: [],
      agentClientTransactionPaymentSchedule: [{ agentId: "", clientId: "", dealRefId: "", paymentDate: "", paymentAmount:'', bankDetail:'', chequeDetail:'', paymentNotificationBeforeDays:"" }]
    }
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.handelSubmitPayment = this.handelSubmitPayment.bind(this);
    
  }
  componentDidMount() {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const clientId = localStorage.getItem('userId');
      const agentId = localStorage.getItem('agentId');
      axios.get('https://rscoreapi.azurewebsites.net/api/AgentClientTransaction/allAgent', { headers: { Authorization: str },
        params: {
          ClientId: clientId,
          AgentId: agentId
        }
      })
      .then(res => {
        const transactions = res.data;
        this.setState({ transactions });
      })
  }
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleRightCanvas() {
    this.setState({transaction: "", isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const transaction = arg;
  
    this.setState({
      transaction: {
        id:transaction.id
      }
    });
    this.toggle();
  };
  handelSubmitPayment(){

  }
  render() {
    const { transaction } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
              title="Projects"
              breadcrumbItem="Transactions"
            />
            <Row>
                 <Col sm="12">
                   <Card>
                     <CardBody>
                     <div className="table-responsive">
                    <Table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          
                        <th scope="col"></th>
                         
                          <th scope="col">Project</th>
                         
                          <th scope="col">Suite #</th>
						              <th scope="col">Cost</th>
                          <th scope="col">Closing Date</th>
                          <th scope="col"></th>
                          
                         
                        </tr>
                      </thead>
                      <tbody>
                      {
                            this.state.transactions
                              .map(transaction =>
                       <tr key={transaction.id}>
                       <td><img src={primg1} className="img-fluid" width="100" /></td>
                         <td>Liberty Market Tower</td>
                         <td>10</td>
                         <td>{transaction.transactionAmount}</td>
                         <td><Moment format="Do MMMM YYYY">{transaction.closingDate}</Moment></td>
                         <td><Link to="/project-detail/34/10da1bf6-7e35-4c83-8435-53072057f347" className="btn btn-primary">Detail</Link>{" "} <Link to="#" onClick={() => this.toggleRightCanvas(transaction)} className="btn btn-primary">Payment Schedule</Link></td>
                       </tr>
                  )}
                      </tbody>
                    </Table>
                    
                  </div>

                     </CardBody>
                   </Card>
               

              </Col>
              
            </Row>


            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                      style={{width:800}}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                      Payment Schedule
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          agentClientTransactionPaymentSchedules: [
                            {

                              agentId: localStorage.getItem('agentId'),
                              clientId: localStorage.getItem('userId'),
                              dealRefId: transaction.id,
                              paymentDate: '', 
                              paymentAmount: 0,
                              bankDetail: '',
                              chequeDetail: '',
                              paymentNotificationBeforeDays: 0
                            }
                          ],
                        
                        }}
                      validationSchema={Yup.object().shape({
                        agentClientTransactionPaymentSchedules: Yup.array().of(
                          Yup.object().shape({
                            paymentDate: Yup.string().required('This is required'),
                            paymentAmount: Yup.number().typeError('Price should be a number'),
                          
                          })
                        )
                      })}
                      onSubmit={this.handelSubmitPayment}
>
  {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
    <Form className="needs-validation">
      <FieldArray
        name="agentClientTransactionPaymentSchedules"
        render={arrayHelpers => (
          <div>
            {values.agentClientTransactionPaymentSchedules.map((paymentSchedule, index) => (
              <div key={index}>
                <Row>
                  <Col sm="2">
                    <FormGroup className="mb-3">
                      <Label htmlFor="paymentDate" className="label-text mt-3">Date *</Label>
                      <Flatpickr
                        className={"form-control" + (errors.paymentDate && touched.paymentDate ? " is-invalid" : "")}
                        value={paymentSchedule.paymentDate}
                        onChange={(date) => setFieldValue(`agentClientTransactionPaymentSchedules.${index}.paymentDate`, date[0])}
                        options={{
                          altInput: true,
                          altFormat: "Y-m-d",
                          dateFormat: "Y-m-d"
                        }}
                      />
                      <ErrorMessage
                        name={`agentClientTransactionPaymentSchedules.${index}.paymentDate`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="2">
                    <FormGroup className="mb-3">
                      <Label htmlFor="paymentAmount" className="label-text mt-3">Amount</Label>
                      <input
                        type="text"
                        name={`agentClientTransactionPaymentSchedules.${index}.paymentAmount`}
                        value={paymentSchedule.paymentAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${errors.agentClientTransactionPaymentSchedules?.[index]?.paymentAmount && touched.agentClientTransactionPaymentSchedules?.[index]?.paymentAmount ? " is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        name={`agentClientTransactionPaymentSchedules.${index}.paymentAmount`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="2">
                    <FormGroup className="mb-3">
                      <Label htmlFor="bankDetail" className="label-text mt-3">Bank</Label>
                      <input
                        type="text"
                        name={`agentClientTransactionPaymentSchedules.${index}.bankDetail`}
                        value={paymentSchedule.bankDetail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${errors.agentClientTransactionPaymentSchedules?.[index]?.bankDetail && touched.agentClientTransactionPaymentSchedules?.[index]?.bankDetail ? " is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        name={`agentClientTransactionPaymentSchedules.${index}.bankDetail`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="2">
                    <FormGroup className="mb-3">
                      <Label htmlFor="chequeDetail" className="label-text mt-3">Cheque #</Label>
                      <input
                        type="text"
                        name={`agentClientTransactionPaymentSchedules.${index}.chequeDetail`}
                        value={paymentSchedule.chequeDetail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${errors.agentClientTransactionPaymentSchedules?.[index]?.chequeDetail && touched.agentClientTransactionPaymentSchedules?.[index]?.chequeDetail ? " is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        name={`agentClientTransactionPaymentSchedules.${index}.chequeDetail`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup className="mb-3">
                      <Label htmlFor={`agentClientTransactionPaymentSchedules.${index}.paymentNotificationBeforeDays`} className="label-text mt-3">Notify me</Label>
                      <Field as="select"  name={`agentClientTransactionPaymentSchedules.${index}.paymentNotificationBeforeDays`}
                                      className={
                                        "form-control" +
                                        (errors.f1 && touched.f1
                                          ? " is-invalid"
                                          : "")
                                      }

                                      onChange={handleChange}>
                                      <option value="">Select</option>
                                      <option value="1">1 day before</option>
                                      <option value="7">1 week before</option>
                                      <option value="14">2 weeks before</option>
                                      <option value="30">1 month before</option>
                                      
                                    </Field>
                     
                      <ErrorMessage
                        name={`agentClientTransactionPaymentSchedules.${index}.paymentNotificationBeforeDays`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  {index === 0 && (
                  <Col sm="1">
                    <div className="clearfix">
                      <button
                        type="button"
                        onClick={() => arrayHelpers.push({})}
                        style={{ marginTop: 42 }} className="btn btn-add"
                      >
                        <i className="bx bx-plus-medical" />
                      </button>
                    </div>
                  </Col>
                )}

                {index !== 0 && (
                  <Col sm="1">
                    <div className="clearfix">
                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        style={{ marginTop: 42 }} className="btn btn-remove"
                      >
                        <i className="bx bx-minus" />
                      </button>
                    </div>
                  </Col>
                )}
                </Row>

               
              </div>
            ))}
          </div>
        )}
      />
      <Col sm="2">
        <div className="clearfix">
          <button type="submit" className="btn btn-primary w-md" style={{ marginTop: 2 }}>
            Save
          </button>
        </div>
      </Col>
    </Form>
  )}
</Formik>
                  <h5 style={{marginTop:40}}>Existing schedules</h5>
                <div className="table-responsive">
                    <Table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          
                     
                         
                          <th scope="col">Date </th>
                         
                          <th scope="col">Amount</th>
						              <th scope="col">Bank</th>
                          <th scope="col">Chq #</th>
                          <th scope="col"></th>
                          
                         
                        </tr>
                      </thead>
                     
                    </Table>
                    
                  </div>
                      </OffcanvasBody>
                    </Offcanvas>
            

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
myTransection.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(myTransection))
